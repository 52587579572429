export default {
	superTitle: "Nosotros",
	title: "Historia Noritex",
	shortDescription: "UN UNIVERSO DE TENDENCIAS",
	since: "Noritex existe desde 1980",
	longStory1:
		"Nos encanta compartir la historia de nuestra gran familia. En 1954 nace ALMACÉN DEL NORTE en el número 39 de Avenida B, ciudad de Panamá, gracias a la visión y el valor de un inmigrante con un gran sueño. Este sueño se materializó con la fundación de Noritex, S.A., en 1981 en la Zona Libre de Colón, bajo el liderazgo de la segunda generación Btesh.",
	longStory2:
		"Con el tiempo y la pasión incansable de nuestro equipo de colaboradores, Noritex se ha consolidado como una de las empresas más destacadas de Latinoamérica.",
	longStory3:
		"Creamos y fortalecimos marcas como Concepts Life, Santini, Di Angelo, Merletto y Noritex Textiles, respaldadas por un extraordinario servicio al cliente, una logística excepcional y un innovador sistema de ventas de tecnología avanzada.",
	longStory4: "",

	productsTitle: "Nuestros Productos",
	productsHelp: "",
	productsHelp2:
		"Nuestras marcas insignias, Concepts Life y Santini Christmas, presentan cada año ferias impresionantes que incluyen más de 50 colecciones y 45,000 productos, lanzando las últimas tendencias en decoración del hogar y navidad para que puedas ofrecerlas a tus clientes. Merletto conjuga novedad, creatividad y tradición en los rubros de artículos escolares y de oficina, así como Di Angelo en hermosas figuras religiosas.",
	productsHelp3:
		"Nuestros productos son reconocidos y comercializados globalmente por estar a la vanguardia en moda, actualidad, calidad y tendencia.",
	products1:
		"En Noritex, la anticipación de tendencias es parte de nuestro ADN.  Nos mantenemos explorando constantemente, nuevas fronteras en productos y diseño de interiores. Nuestro equipo de expertos siempre está atento a las últimas tendencias y movimientos del mercado, asegurando que nuestro portafolio esté siempre un paso adelante. Desde la selección de materiales hasta el diseño de productos, cada aspecto de nuestras marcas refleja nuestro compromiso con la innovación y la excelencia.",
	product2: "",
	products3: "¡Bienvenidos al mundo de Noritex!",

	features: [
		{
			title: "Logística avanzada",
			img: "https://www.noritex.com/PdpzChfc6vMePfkvFbodega.jpg",
			paragraph:
				"Contamos con la logística más avanzada de Latinoamérica con más de 100 personas dedicadas a brindarle todos sus productos a tiempo.",
		},
		{
			title: "Inversión en el medio ambiente",
			action: "modal",
			img: "https://www.noritex.com/eC2k6SBqTc8NZPYkmPaneles_Solares_Noritex.png",
			paragraph:
				"Nos dedicamos a nuestro medio ambiente, por eso hemos abierto dos parques solares y estamos produciendo el 80% de nuestro consumo eléctrico.",
		},
		{
			title: "Estudio de tendencias",
			img: "https://www.noritex.com/uplds/2024/5/6/8K6XZT6XiEZueqYQAJapandi_Studio_Portada.jpg",
			paragraph:
				"Contamos con un equipo de expertos en tendencias que se dedican a explorar constantemente nuevas fronteras en productos.",
		},
		{
			title: "Apoyo a nuestra comunidad",
			img: "https://www.noritex.com/LeiYgFXJfk5kQZTXTScreen_Shot_2020-11-05_at_1.27.30_PM.png",
			paragraph:
				"Creemos en ayudar a nuestra comunidad local a prosperar, por eso nos dedicamos a ayudar a crecer y revitalizar nuestra comunidad.",
		},
	],
};
