import React from "react";
import Menu from "./Menu";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import withTracker from "../../../blueberry/connectPromise";

// import lang_es from "./lang/lang_es";
// import lang_en from "./lang/lang_en";
// import lang_pt from "./lang/lang_pt";

// function getLang(lang) {
//     switch (lang) {
//         case "es":
//             return lang_es;
//         case "en":
//             return lang_en;
//         case "pt":
//             return lang_pt;
//     }
// }

class Page extends ProductsSectionTemplate {
    state = {};

    renderFooter = () => null;

    renderBody() {
        const { lang, menuIndex } = this.props.match.params;
        const { jsonSettings, history } = this.props;
        const { user, menus } = this.props.store;
        return (
            <Menu
                menuIndex={menuIndex}
                menus={menus}
                isLoggedIn={this.isLoggedIn()}
                user={user}
                lang={lang}
                history={history}
                jsonSettings={jsonSettings}
            />
        );
    }
}

export default withTracker()(Page);

// export default withSSR(Page);
