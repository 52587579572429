import React, { useState } from "react";
import { Top, CartIcon, Cart, Header, Links, Closer, Items } from "./style";
import { Title } from "../../../sdk/Layout";
import Icons from "../Icons";
import IconsLibrary from "../../../components/Icons2";
import InternalLink from "../../../components/InternalLink";
import { Buttons, Button, Icon } from "../../../sdk/Button";
import { Preloader } from "../../../sdk/Elements";
import { useTranslation } from "../../../../lang";
import { numberWithCommas } from "../../../utils/index";
import Blueberry from "../../../../blueberry";

function App({ onClose, cart, stats, inCollection, removeItem }) {
    const [_t] = useTranslation();
    const [showCart, setShowCart] = useState(false);
    const [limit, setLimit] = useState(50);

    const [isGeneratingProforma, setIsGeneratingisGeneratingProforma] =
        React.useState(false);
    const toggleCart = () => {
        setShowCart(!showCart);
    };

    const handleProformaGenerate = (e) => {
        if (analytics) analytics.track("Cart Proforma");
        const sort = e.currentTarget.getAttribute("data-sort");
        setIsGeneratingisGeneratingProforma(true);
        const windowReference = window.open("", "_blank");
        Blueberry.generateProforma({}).then((resp) => {
            windowReference.location = `${resp}${sort ? "&sort=" + sort : ""}`;
            // window.open(`${resp}${sort ? "&sort=" + sort : ""}`, "_blank");
            setIsGeneratingisGeneratingProforma(false);
        });
    };

    const handleOnScroll = (e) => {
        if (
            e.target.scrollHeight - e.target.scrollTop ===
            e.target.clientHeight
        ) {
            setLimit((prev) => prev + 100);
        }
    };

    const itemsMapped = [];
    let currentCount = 0;
    for (var j = 0; j < cart.length; j++) {
        const currentCart = cart[j];
        const nextMax = limit - currentCount;

        for (
            var i = 0;
            i <= Math.min(currentCart.items.length - 1, nextMax);
            i++
        ) {
            const index = currentCart.items[i];
            currentCount++;
            itemsMapped.push(
                <li key={index.productId}>
                    <div>
                        <img
                            src={
                                index.imagesUrl && index.imagesUrl.length > 0
                                    ? index.imagesUrl[0].thumb
                                    : ""
                            }
                            alt={index.title}
                        />
                        <div>
                            <h5>{index.title}</h5>
                            <span>QTY: {index.quantity}</span>
                            <p>
                                {numberWithCommas(index.price * index.quantity)}
                            </p>
                            <IconsLibrary
                                onClick={() =>
                                    removeItem(index.productId, index.slug)
                                }
                                icon="trash"
                            ></IconsLibrary>
                        </div>
                    </div>
                </li>
            );
        }
    }

    return (
        <>
            <Top>
                {/* <Button white><Icons icon="close-light"></Icons></Button> */}
                {inCollection ? (
                    <Button white onClick={onClose}>
                        <IconsLibrary icon="close"></IconsLibrary>
                    </Button>
                ) : (
                    <Button white small onClick={onClose}>
                        <IconsLibrary icon="chevron-left-light"></IconsLibrary>
                        <span>{_t("allAmbients")}</span>
                    </Button>
                )}

                <CartIcon>
                    <Icon onClick={() => toggleCart()}>
                        <IconsLibrary icon="shopping-cart"></IconsLibrary>
                    </Icon>
                </CartIcon>
            </Top>

            <Cart isVisible={showCart} onScroll={handleOnScroll}>
                <Header>
                    <div>
                        <Closer onClick={() => toggleCart()}>
                            <Icons icon="close-light"></Icons>
                        </Closer>
                        <Title as="h3" size="6">
                            {_t("cart")}
                        </Title>
                        <ul>
                            <li>Total: {numberWithCommas(stats.total)}</li>
                            <li>CMB: {stats.cbm.toFixed(4)}</li>
                        </ul>
                    </div>

                    <Links>
                        <a onClick={handleProformaGenerate}>
                            <IconsLibrary icon="excel-blank"></IconsLibrary>
                            <span>{_t("export")} Excel</span>
                        </a>
                        <InternalLink to="/cart/">
                            <IconsLibrary icon="shopping-cart"></IconsLibrary>
                            <span>{_t("gotoCart")}</span>
                        </InternalLink>
                    </Links>
                </Header>

                <Items>
                    {/* <li>
                        <Preloader></Preloader>
                        <Preloader half></Preloader>
                    </li> */}
                    {itemsMapped}
                </Items>
            </Cart>
        </>
    );
}

export default App;
