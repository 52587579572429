import styled, { css } from "styled-components";

export const isCurrentMixin = css`
    background-color: #405676;
    h3 {
        color: white;
        font-weight: bold;
    }
`;

export const Item = styled.div`
    width: 99%;
    margin-right: 0.5%;
    margin-left: 0.5%;
    background-color: ${props => props.theme.grayLight};
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 100ms linear;
    img {
        display: block;
        border-radius: 5px;
        overflow: hidden;
        width: 100%;
        height: 100px;
        object-fit: cover;
    }
    h3 {
        font-size: 16px;
        padding: 10px 0 4px 0;
        margin: 0;
        font-weight: normal;
        color: ${props => props.theme.gray};
    }
    ${(props) => props.isCurrent && isCurrentMixin}

    @media (max-width: 768px) {
        img {
            height: 80px;
        }
        h3 {
            font-size: 14px;
        }
    }
`;

export const Carousel = styled.div`
    display: flex;
    position: relative;
    padding: 0px 30px;
`;

export const isDisabledMixin = css`
    opacity: 0.2;
    cursor: default;
`;

export const Next = styled.div`
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 200ms linear;
    svg {
        width: 12px;
    }

    ${(props) => props.isDisabled && isDisabledMixin}
`;

export const Prev = styled(Next)`
    right: inherit;
    left: 7px;
`;
