import React from "react";
import PropTypes from "prop-types";

import SwipeableViews from "react-swipeable-views";
import Icons from "../components/Icons2";
import InternalLink from "../components/InternalLink";
import renderIf from "../utils/renderIf";
import useWindowSize from "../hooks/useWindowSize";
import { Container, Section } from "../sdk/Layout";

import { Item, Carousel, Next, Prev } from "./styleCollection";

const Slider = ({
    items,
    width,
    columns,
    activeSlug,
    activeItemIndex,
    setActiveItemIndex,
    onNextSlide,
    onLastSlide,
    countOfSlides,
    inSlide,
}) => {
    const slides = [];
    let currentSlide = [];

    columns = { ...columns };

    // if (columns.large > items.length) columns.large = items.length;
    // if (columns.medium > items.length) columns.medium = items.length;

    for (let i = 0; i < items.length; i++) {
        const index = items[i];

        let img =
            index.detail &&
            index.detail.thumbs &&
            index.detail.thumbs.length > 0
                ? index.detail.thumbs[0]
                : "";

        if (index.images && index.images.length > 0) {
            img = `${index.images[0]}?d=400x400`;
        }

        currentSlide.push(
            <Item
                key={`${index.key ? index.key : index._id}`}
                onClick={index.onClick}
                isCurrent={index.slug === activeSlug}
                tabIndex={0}
                count={items.length}
            >
                <InternalLink to={`/ambient/${index.slug}`}>
                    <img src={img} alt={index.title} />
                    <h3>{index.title}</h3>
                </InternalLink>
            </Item>
        );

        if ((i > -1 && (i + 1) % inSlide() === 0) || i === items.length - 1) {
            slides.push(
                <Carousel key={`Slide-${i}`}>
                    {currentSlide}

                    <Prev
                        isDisabled={activeItemIndex <= 0}
                        onClick={() => activeItemIndex > 0 && onLastSlide()}
                    >
                        <Icons icon="chevron-left"></Icons>
                    </Prev>
                    <Next
                        isDisabled={activeItemIndex >= countOfSlides - 1}
                        onClick={() =>
                            activeItemIndex < countOfSlides - 1 && onNextSlide()
                        }
                    >
                        <Icons icon="chevron-right"></Icons>
                    </Next>
                </Carousel>
            );
            currentSlide = [];
        }
    }

    if (items == null || items == null || items.length === 1) return null;

    return (
        <SwipeableViews
            enableMouseEvents
            index={activeItemIndex}
            onChangeIndex={setActiveItemIndex}
        >
            {slides}
        </SwipeableViews>
    );
};

function CarouselAmbient({ items, strings, columns, activeSlug }) {
    const { width } = useWindowSize();

    const inSlide = () => {
        if (width >= 1200) return columns.large;
        if (width >= 992) return columns.medium;
        if (width >= 768) return columns.small;
        if (width >= 410) return columns.xsmall;
        return columns.xxsmall || columns.xsmall;
    };

    const countOfSlides = Math.ceil(items.length / inSlide());

    const [activeItemIndex, setActiveItemIndex] = React.useState(() => {
        let newActive = 0;

        items.map((index, i) => {
            if (index.slug === activeSlug) {
                newActive = Math.ceil((i + 1) / inSlide()) - 1;
                newActive = newActive > 0 ? newActive : 0;
            }
        });

        return newActive;
    });

    const onNextSlide = () => {
        setActiveItemIndex((prev) => prev + 1);
    };

    const onLastSlide = () => {
        setActiveItemIndex((prev) => prev - 1);
    };

    React.useEffect(() => {
        let newActive = 0;

        items.map((index, i) => {
            if (index.slug === activeSlug) {
                newActive = Math.ceil((i + 1) / inSlide()) - 1;
            }
        });

        newActive = newActive > 0 ? newActive : 0;

        setActiveItemIndex(newActive);
    }, [width, items]);

    if (items == null) return null;
    return (
        <Section>
            <Container isFluid>
                <Slider
                    width={width}
                    activeSlug={activeSlug}
                    activeItemIndex={activeItemIndex}
                    setActiveItemIndex={setActiveItemIndex}
                    onLastSlide={onLastSlide}
                    onNextSlide={onNextSlide}
                    items={items}
                    columns={columns}
                    strings={strings}
                    inSlide={inSlide}
                    countOfSlides={countOfSlides}
                />
            </Container>
        </Section>
    );
}

CarouselAmbient.propTypes = {
    width: PropTypes.number,
    items: PropTypes.array,
    collections: PropTypes.object,
    onClick: PropTypes.func,
};

CarouselAmbient.defaultProps = {
    columns: {
        large: 6,
        medium: 6,
        small: 4,
        xsmall: 2,
    },
};

export default CarouselAmbient;
