import React from "react";
import styled from "styled-components";
import { Container, Section, Heading } from "../../sdk/Layout";
import { Preloader } from "../../sdk/Elements";
import InternalLink from "../InternalLink";
import useWindowSize from "../../hooks/useWindowSize";
import { Button, Buttons } from "../../sdk/Button";
// import { animateScroll as scroll } from "react-scroll";

const StackGrid = styled.div`
    column-count: ${(props) => props.viewWidth <= 500 ? 2 : 5};
    column-width: ${(props) => props.viewWidth <= 500 ? "50%" : "236px"};
    margin-bottom: 30px;
`;

const Item = styled.div`
    max-width: 236px;
    break-inside: avoid;
    text-align: center;
    cursor: pointer;
    margin: 0 auto;
    margin-bottom: 20px;
    figure {
        overflow: hidden;
        border-radius: 5px;
        img {
            display: block;
            width: 100%;
        }
    }
    h3 {
        font-size: 16px;
        line-height: 20px;
        padding: 10px 0 0 0;
        margin: 0;
        font-weight: bold;
        color: ${(props) => props.theme.primaryDark};
    }
    @media (max-width: 520px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

function Mosonry({ children, onClick, preventClick, loading }) {
    const { width } = useWindowSize();
    const breakpointColumnsObj = {
        default: 6,
        980: 5,
        768: 4,
        680: 3,
        520: 2,
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <>
            <Section>
                <Container>
                    {loading ? (
                        <StackGrid
                            viewWidth={width}
                        >
                            <Item>
                                <Preloader square />
                            </Item>
                            <Item>
                                <Preloader square />
                            </Item>
                            <Item>
                                <Preloader square />
                            </Item>
                            <Item>
                                <Preloader square />
                            </Item>
                            <Item>
                                <Preloader square />
                            </Item>
                            <Item>
                                <Preloader square />
                            </Item>
                        </StackGrid>
                    ) : (
                        <StackGrid
                            viewWidth={width}
                        >
                            {children.map((index, i) => {
                                let img =
                                    index.imagesUrl &&
                                    index.imagesUrl.length > 0
                                        ? index.imagesUrl[0].thumb
                                        : "";
                                let imageLarge =
                                    index.imagesUrl &&
                                    index.imagesUrl.length > 0
                                        ? index.imagesUrl[0].url
                                        : "";
                                if (img === "") {
                                    img =
                                        index.images && index.images.length > 0
                                            ? index.images[0]
                                            : "";
                                }
                                if (imageLarge === "") {
                                    imageLarge =
                                        index.images && index.images.length > 0
                                            ? index.images[0]
                                            : "";
                                }
                                return (
                                    <Item key={index._id}>
                                        <InternalLink
                                            preventClick={preventClick}
                                            onClick={() => {
                                                if (onClick)
                                                    onClick(imageLarge);
                                            }}
                                            to={`/ambient/${index["parent-slug"]}/${i}`}
                                        >
                                            <figure>
                                                <img
                                                    src={img}
                                                    alt={index.title}
                                                />
                                            </figure>
                                            <h3>{index.title}</h3>
                                        </InternalLink>
                                    </Item>
                                );
                            })}
                        </StackGrid>
                    )}

                    <Buttons isCentered>
                        <Button responsive onClick={scrollToTop}>
                            Volver arriba
                        </Button>
                    </Buttons>
                </Container>
            </Section>
        </>
    );
}

export default Mosonry;
