export default {
    superTitle: "Nosotros",
    title: "Historia Noritex",
	shortDescription: "UM UNIVERSO DE TENDÊNCIAS",
    since: "Noritex existe desde 1980",
    longStory1:
        "Adoramos compartilhar a história da nossa grande família. Em 1954, nasceu o ALMACÉN DEL NORTE no número 39 da Avenida B, na Cidade do Panamá, graças à visão e à coragem de um imigrante com um grande sonho. Este sonho se materializou com a fundação da Noritex, S.A., em 1981 na Zona Livre de Colón, sob a liderança da segunda geração Btesh.",
    longStory2:
        "Com o tempo e a incansável paixão da nossa equipe de colaboradores, a Noritex se consolidou como uma das empresas mais destacadas da América Latina.",
    longStory3:
        "Criamos e fortalecemos marcas como Concepts Life, Santini, Di Angelo, Merletto e Noritex Textiles, apoiadas por um serviço ao cliente extraordinário, uma logística excepcional e um inovador sistema de vendas com tecnologia avançada.",
    longStory4:
        "",

    productsTitle: "Nuestros Productos",
    productsHelp:
        "",
	productsHelp2:
		"Nossas marcas insignia, Concepts Life e Santini Christmas, realizam feiras impressionantes todos os anos, apresentando mais de 50 coleções e 45.000 produtos, lançando as últimas tendências em decoração de casa e Natal para que você possa oferecê-las aos seus clientes. Merletto combina novidade, criatividade e tradição nos setores de artigos escolares e de escritório, assim como Di Angelo em belas figuras religiosas.",
	productsHelp3:
        "Nossos produtos são reconhecidos e comercializados globalmente por estarem na vanguarda da moda, atualidade, qualidade e tendência.",
    products1:
        "Na Noritex, a antecipação de tendências faz parte do nosso DNA. Estamos constantemente explorando novas fronteiras em produtos e design de interiores. Nossa equipe de especialistas está sempre atenta às últimas tendências e movimentos de mercado, garantindo que nosso portfólio esteja sempre um passo à frente. Desde a seleção de materiais até o design de produtos, cada aspecto de nossas marcas reflete nosso compromisso com a inovação e a excelência.",
    product2:
        "",
    products3: "Bem-vindo ao mundo da Noritex!",

    features: [
        {
            title: "Logística avanzada",
            img: "https://www.noritex.com/PdpzChfc6vMePfkvFbodega.jpg",
            paragraph:
                "Contamos con la logística más avanzada de Latinoamérica con más de 100 personas dedicadas a brindarle todos sus productos a tiempo.",
        },
        {
            title: "Inversión en el medio ambiente",
            action: "modal",
            img:
                "https://www.noritex.com/eC2k6SBqTc8NZPYkmPaneles_Solares_Noritex.png",
            paragraph:
                "Nos dedicamos a nuestro medio ambiente, por eso hemos abierto dos parques solares y estamos produciendo el 80% de nuestro consumo eléctrico.",
        },
        {
            title: "Apoyo a nuestra comunidad",
            img:
                "https://www.noritex.com/LeiYgFXJfk5kQZTXTScreen_Shot_2020-11-05_at_1.27.30_PM.png",
            paragraph:
                "Creemos en ayudar a nuestra comunidad local a prosperar, por eso nos dedicamos a ayudar a crecer y revitalizar nuestra comunidad.",
        },
    ],
};
