export default {
	superTitle: "About Us",
	title: "Noritex History",
	shortDescription:
		"A UNIVERSE OF TRENDS",
	since: "Since 1980",
	longStory1:
		"We love sharing the story of our great family. In 1954, ALMACÉN DEL NORTE was born at 39 Avenida B, Panama City, thanks to the vision and courage of an immigrant with a big dream. This dream materialized with the founding of Noritex, S.A., in 1981 in the Colón Free Zone, under the leadership of the second Btesh generation.",
	longStory2:
		"Over time and with the tireless passion of our team of collaborators, Noritex has become one of the most prominent companies in Latin America.",
	longStory3:
		"We created and strengthened brands such as Concepts Life, Santini, Di Angelo, Merletto, and Noritex Textiles, backed by outstanding customer service, exceptional logistics, and an innovative, advanced technology sales system.",
	longStory4:
		"",

	productsTitle: "Our Products",
	productsHelp:
		"",
	productsHelp2:
		"Our flagship brands, Concepts Life and Santini Christmas, host impressive fairs every year, featuring more than 50 collections and 45,000 products, launching the latest trends in home and Christmas decoration for you to offer to your customers. Merletto combines novelty, creativity, and tradition in school and office supplies, while Di Angelo specializes in beautiful religious figures.",
	productsHelp3:
		"Our products are recognized and marketed globally for being at the forefront of fashion, innovation, quality, and trends.",
	products1:
		"At Noritex, trend anticipation is part of our DNA. We are constantly exploring new frontiers in products and interior design. Our team of experts is always on the lookout for the latest trends and market movements, ensuring that our portfolio is always one step ahead. From material selection to product design, every aspect of our brands reflects our commitment to innovation and excellence.",
	product2:
		"",
	products3: "Welcome to the world of Noritex!",

	features: [
		{
			title: "Superior Logistics",
			img: "https://www.noritex.com/PdpzChfc6vMePfkvFbodega.jpg",
			paragraph:
				"With a team of over 100 dedicated professionals, we boast one of the most efficient logistics networks in Latin America. Our commitment is to ensure timely delivery of all your ordered products",
		},
		{
			title: "Commitment to Sustainability",
			action: "modal",
			img: "https://www.noritex.com/eC2k6SBqTc8NZPYkmPaneles_Solares_Noritex.png",
			paragraph:
				"We deeply care about our planet, which is why we've established two solar farms that now produce 80% of our energy needs. This reflects our dedication to environmental sustainability and clean energy.",
		},
		{
			title: "Community Engagement",
			img: "https://www.noritex.com/LeiYgFXJfk5kQZTXTScreen_Shot_2020-11-05_at_1.27.30_PM.png",
			paragraph:
				"We strongly believe in giving back to our local community. By investing in growth and revitalization initiatives, we strive to contribute to a thriving community that benefits all its members.",
		},
	],
};
