import styled from "styled-components";
import { Container, Section, Box } from "../../sdk/Layout";

import { Button } from "../../sdk/Button";
import { Tag, Modal } from "../../sdk/Elements";

export const BoxExtended = styled(Box)`
    position: relative;
    padding-right: 60px;
    /* min-height: 71px; */
    ${Button} {
        position: absolute;
        right: 20px;
        top: 20px;
        border: none;
    }
`;

export const Search = styled.label`
    > input {
        display: none;
    }
    display: block;
    cursor: text;
    margin-left: -5px;
    margin-top: -5px;
    margin-bottom: -5px;
    margin-right: -5px;
`;

export const Term = styled(Tag)`
    margin: 5px;
    font-size: 16px;
    cursor: default;
    vertical-align: middle;
    a {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
            width: 16px;
            height: 16px;
            color: ${(props) => props.theme.gray};
        }
    }
`;

export const NewTerm = styled.div`
    display: inline-block;
    vertical-align: middle;
    input {
        height: 41px;
        font-size: 16px;
        border: none;
        background-color: transparent;
    }
`;
