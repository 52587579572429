/* eslint-disable react/prop-types */
import React from "react";
import SwipeableViews from "react-swipeable-views";
import { getImageUrl } from "../../../utils";
import { Brand, Slider, Info } from "../style";

function BrandItem({ brand, lang }) {
    const [activeItemIndex, setActiveItemIndex] = React.useState(0);
    const maxSlides = brand.imagesUrl ? brand.imagesUrl.length : 0;
    const changeActiveItem = (activeIndex) => {
        setActiveItemIndex(activeIndex);
    };

    const nextItem = (e) => {
        e.preventDefault();
        changeActiveItem((prev) =>
            prev + 1 >= maxSlides ? maxSlides - 1 : prev + 1
        );
    };
    const lastItem = (e) => {
        e.preventDefault();
        changeActiveItem((prev) => (prev - 1 <= 0 ? 0 : prev - 1));
    };

	if (!brand || brand.hideForWeb) return null;

    return (
        <Brand to={`/${brand.slug}/brand`}>
            <Slider>
                {brand.imagesUrl && brand.imagesUrl.length > 1 ? (
                    <div className="slider-navigation">
                        <span onClick={lastItem} tabIndex="0"></span>
                        <span onClick={nextItem} tabIndex="0"></span>
                    </div>
                ) : null}
                <SwipeableViews
                    enableMouseEvents
                    index={activeItemIndex}
                    onChangeIndex={changeActiveItem}
                >
                    {brand.imagesUrl
                        ? brand.imagesUrl.map((index, i) => (
                              <div
                                  className="slider-track"
                                  key={`${brand._id}-slide-${i}`}
                              >
                                  <div className="slider-item">
                                      <figure>
                                          <img
                                              src={`${index.url}?d=400x400`}
                                              alt={brand.name}
                                          />
                                      </figure>
                                  </div>
                              </div>
                          ))
                        : null}
                </SwipeableViews>

                <div className="slider-pagination">
                    {brand.imagesUrl && brand.imagesUrl.length > 1 ? (
                        <ul>
                            {brand.imagesUrl.map((index, i) => (
                                <li
                                    key={`${brand._id}-action-${i}`}
                                    className={
                                        activeItemIndex === i ? "active" : ""
                                    }
                                ></li>
                            ))}
                        </ul>
                    ) : null}
                </div>
            </Slider>
			<Info>
				<figure className="brand-logo">
					<img
						src={(lang === "en" && brand.imageLogoEn) ? brand.imageLogoEn : brand.imageLogo}
						alt={`${brand.name} Logo`}
					/>
				</figure>
				{/* <h3>{brand.name}</h3>
                <ul className="meta">
                    <li>{brand.minimun}</li>
                </ul> */}
			</Info>
        </Brand>
    );
}

export default BrandItem;
