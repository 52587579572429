import React from "react";

import { Helmet } from "react-helmet";
import Listener from "../components/Listener";
import withTracker from "../../blueberry/connectPromise";
import Blueberry from "../../blueberry/index";
import ProductsSectionTemplate from "../templates/ProductsSectionTemplate";
import TopBar from "./components/TopBar";

import Loader from "../components/Loader";

import CarouselAmbient from "./CarouselAmbient";
import Mosonry from "../components/Mosonry";

class AmbientCollection extends ProductsSectionTemplate {
	state = {
		...this.productDefaultState,
		isLoading: false,
		isCartOpen: false,
		isLoadingMenu: false,
		activeItemIndex: 0,
		width: 1200,
		menu: {
			menu: [],
		},
		products: {
			products: [],
		},
	};

	componentDidMount() {
		this.retrieveCollection();
		this.backwardsFindMenu();
	}

	componentDidUpdate(prevProps) {
		const { collection, index } = this.props.match.params;

		if (collection !== prevProps.match.params.collection) {
			this.retrieveCollection();
		}
	}

	getData = () => {
		this.retrieveCollection();
		this.backwardsFindMenu();
	};

	onClose = () => {
		const { lang, collection } = this.props.match.params;
		if (
			typeof window !== "undefined"
            && window.__lastAmbient != null
            && window.__lastAmbient !== ""
		) {
			this.props.history.replace(window.__lastAmbient);
		} else {
			this.props.history.replace(`/${lang}/collection/${collection}/`);
		}
	};

	retrieveCollection = () => {
		const { collection } = this.props.match.params;
		// const currentAmbient = this.props.children[index];
		this.setState({ isLoading: true });
		Blueberry.collectionChildren({ slug: collection, limit: 200 }).then(
			(resp) => {
				this.setState({ children: resp, isLoading: false });
			},
		);
	};

	backwardsFindMenu = () => {
		const { collection } = this.props.match.params;
		// const currentAmbient = this.props.children[index];
		this.setState({ isLoadingMenu: true });
		Blueberry.backwardsFindMenu(collection)
			.then((resp) => {
				this.setState({ menu: resp, isLoadingMenu: false });
			})
			.catch((err) => {
				this.setState({ menu: [], isLoadingMenu: false });
			});
	};

	renderHeader = () => null;

	renderFooter = () => null;

	renderStacks = () => {
		const {
			children, isLoading, isLoadingMenu, width,
		} = this.state;
		if (isLoading || isLoadingMenu) return <Loader />;
		if (children == null) return null;
		return <Mosonry children={children} />;
	};

	renderBody() {
		const { menu } = this.state;
		const { cart, stats, user } = this.props.store;
		const { collection } = this.props.match.params;

		// if (isLoading || isLoadingMenu) return <Loader />;

		return (
			<>
				<Helmet>
					<title>Ambientes</title>
					<meta name="description" content="" />
					<meta
						name="keywords"
						content="noritex,wholesale,decoracion,mayorista,hogar,navidad,textil,religioso"
					/>
					<body className="reset-body" />
				</Helmet>
				<TopBar
					onClose={this.onClose}
					cart={cart}
					stats={stats}
					inCollection
					removeItem={this.removeItem}
				/>
				{menu && menu.menu ? (
					<CarouselAmbient
						items={menu.menu}
						activeSlug={collection}
					/>
				) : null}
				{/* <Section>
                    <Container isFluid>
                        <Carousel>
                            {menu.menu.map((index) => {
                                let img =
                                    index.detail &&
                                    index.detail.thumbs &&
                                    index.detail.thumbs.length > 0
                                        ? index.detail.thumbs[0]
                                        : "";

                                if (index.images && index.images.length > 0) {
                                    img = index.images[0];
                                }
                                return (
                                    <InternalLink
                                        key={index.title + "ambient-menu"}
                                        to={`/ambient/${index.slug}`}
                                    >
                                        <Item>
                                            <img src={img} alt="" />
                                            <h3>
                                                <div>{index.title}</div>
                                            </h3>
                                        </Item>
                                    </InternalLink>
                                );
                            })}
                        </Carousel>
                    </Container>
                </Section> */}
				{this.renderStacks()}
				<Listener user={user} action={this.getData} />
			</>
		);
	}
}

export default withTracker()(AmbientCollection);
