import styled, { css, keyframes } from "styled-components";
import { Container, Title } from "../../sdk/Layout";
import { Button, Icon } from "../../sdk/Button";

export const DemoWrapMobile = styled(Container)`
    max-width: 768px !important;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const fadeInRight = keyframes`
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const MobileNav = styled.div`
    background-color: #fff;
    border-bottom: solid 1px #e3e3e3;
    nav {
        padding: 0 10px;
    }
    ${Title} {
        padding: 10px;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    &.fade-in-right {
        animation: 0.3s ${fadeInRight} ease-out;
    }
    ul {
        margin: 0;
        padding: 0;
    }
`;

export const hasChildrenMixin = css`
    &:before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-right: solid 2px #797b7b;
        border-bottom: solid 2px #797b7b;
        position: absolute;
        right: 10px;
        top: 13px;
        transform: rotate(45deg);
    }
`;

export const isOpenMixin = css`
    &:before {
        transform: rotate(-135deg);
    }
    nav {
        display: block;
    }
`;

export const Item = styled.li`
    font-weight: bold;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #3a3c3d;
    padding: ${(props) => (props.addPadding ? "12px 0" : "0px 0")};
    font-size: 16px;
    cursor: pointer;
    position: relative;
    flex-wrap: wrap;
    figure {
        margin-right: 10px;
        /* svg {
            width: 51px;
            height: 50px;
            display: block;
            margin: auto 16px;
        } */
    }
    > a {
        font-weight: bold;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #3a3c3d;
        padding: 12px 0;
        font-size: 16px;
        cursor: pointer;
        position: relative;
        flex-wrap: wrap;
        width: 100%;
    }
    nav {
        display: block;
        width: 100%;
        padding-top: 10px;
        display: none;
        animation: 0.5s ${fadeIn} ease-out;
        li {
            font-weight: normal;
            padding: 6px 0;
            color: #6f7070;
            a {
                color: #6f7070;
            }
        }
    }

    &:not(:first-child) {
        border-top: solid 1px #e3e3e3;
    }
    img,
    svg {
        width: 120px;
        height: 80px;
        object-fit: cover;
        margin-right: 10px;
    }

    @media (max-width: 580px) {
        img,
        svg {
            width: 80px;
            height: 50px;
        }
        font-size: 14px;
    }

    ${(props) => props.hasChildren && hasChildrenMixin}
    ${(props) => props.isOpen && isOpenMixin}
`;

export const Header = styled.div`
    position: relative;
    background-color: black;
    max-height: 250px;
    &:before {
        content: "";
        padding-top: 50%;
        display: block;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        object-fit: cover;
    }
    > div {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        text-align: center;
        padding: 0px 5px;
        transform: translateY(-50%);
    }
    ${Icon} {
        position: absolute;
        top: 10px;
        left: 10px;
        color: #fff;
        z-index: 2;
        cursor: pointer;
        svg {
            color: #fff;
        }
    }
    ${Title} {
        margin-bottom: 10px;
        color: #fff;
    }
    ${Button} {
        margin: 20px auto;
        display: block;
    }
`;

export const Feaured = styled.div`
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1%;
        margin-top: -1%;
        margin-right: -1%;
        margin-bottom: -1%;
        padding: 10px;
        li {
            display: block;
            width: 48%;
            margin: 1%;
            position: relative;
            background-color: #000;
            &:before {
                display: block;
                padding-top: 60%;
                content: "";
            }
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0.7;
            }
            a {
                text-decoration: none;
                color: white;
            }
            h3 {
                color: white;
                position: absolute;
                bottom: 10px;
                left: 10px;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 14px;
                line-height: 14px;
            }
        }
    }
`;
