import React from "react";
import styled, { css } from "styled-components";
import {
	Container,
	Section,
	Columns,
	Column,
	Title,
} from "../../../sdk/Layout";
import { Button } from "../../../sdk/Button";
import {
	Intro,
	Cols,
	Text,
	Highlight,
	Image1,
	Image2,
	LeftCol,
	RightCol,
} from "../style";

function Historia({ langJson }) {
	return (
		<>
			<Section>
				<Intro as="h2" size="2">
					{langJson.shortDescription}
				</Intro>

				<Container>
					<Cols>
						<Column>
							<Text alignRight>
								<p>{langJson.longStory1}</p>
								<p>{langJson.longStory2}</p>
								<Highlight>{langJson.since}</Highlight>
							</Text>
						</Column>

						<Column>
							<Text>
								<p>{langJson.longStory3}</p>
								<p>{langJson.longStory4}</p>
							</Text>
						</Column>
					</Cols>
				</Container>

				<Cols>
					<LeftCol>
						<Image1>
							<img
								src="https://www.noritex.com/B6wYGgwfdQ5qBEMCfMandala-cole.jpg"
								alt=""
							/>
						</Image1>
					</LeftCol>
					<RightCol>
						<Image2>
							<img
								src="https://www.noritex.com/EKWoX3KxpR56qoDpKshutterstock_624821414_(1).jpg"
								alt=""
							/>
							<Text>
								<p>{langJson.products1}</p>
								<p>{langJson.product2}</p>
								<Title as="h3" size="4">
									{langJson.products3}
								</Title>
							</Text>
						</Image2>
					</RightCol>
				</Cols>
			</Section>
		</>
	);
}

export default Historia;
