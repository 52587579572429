import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Blueberry from "../../../blueberry";
import { Preloader } from "../../sdk/Elements";

import { Container, Title } from "../../sdk/Layout";
import { Button, Icon } from "../../sdk/Button";
import { DemoWrapMobile, MobileNav, Item, Header, Feaured } from "./style";
import InternalLink from "../../components/InternalLink";
import BackIcon from "../../components/Header/icons/BackIcon";
import { checkTags } from "../../components/Header";
import PercentIcon from "../../components/Header/icons/PercentIcon";
import { useTranslation } from "../../../lang";
import { Helmet } from "react-helmet";

const logoutSlug = "menu-principal";
const loginSlug = "menu-principal-login";

function StandAloneMenu({
    menus,
    isLoggedIn,
    user,
    jsonSettings,
    history,
    lang,
    menuIndex,
}) {
    const [menu, setMenu] = useState(false);
    const [isLoadingMenu, setIsLoadingMenu] = useState(true);
    const [_t] = useTranslation();

    const menuIndexOpen = menuIndex ? parseInt(menuIndex) : null;

    const [menuIndexThirdLevelOpen, setMenuIndexThirdLevelOpen] =
        React.useState(null);

    const onShowMenuIndex = (index) => {
        history.push(`/${lang}/stand-alone/${index}`);
    };

    const onSetThirdMenu = (index) => {
        setMenuIndexThirdLevelOpen((prev) => (index === prev ? null : index));
    };

    const backButton = () => {
        setMenuIndexThirdLevelOpen(null);
        history.goBack();
    };

    let config = menus && menus[logoutSlug] ? menus[logoutSlug].menu : [];
    if (isLoggedIn)
        config = menus && menus[loginSlug] ? menus[loginSlug].menu : [];

    const menuToMap =
        menuIndexOpen != null && config[menuIndexOpen]
            ? config[menuIndexOpen].children
            : config;

    const menuTitle =
        menuIndexOpen != null && config[menuIndexOpen]
            ? config[menuIndexOpen].title
            : "";

    const buildHeaderMovil = () => {
        if (config == null) return;
        const tags = user ? user.tags : [];

        const header = menuToMap.map((index, i) => {
            const title = index.title.toLowerCase();

            if (!checkTags(title, tags, jsonSettings, user)) return null;

            if (title === "action items") return null;

            if (index.children == null || index.children.length === 0) {
                console.log("children null");
                return (
                    <Item key={`${index.title}-${i}`}>
                        <InternalLink {...index}>
                            <LazyLoadImage
                                effect="blur"
                                src={
                                    index.thumbs && index.thumbs.length > 0
                                        ? index.thumbs[0]
                                        : ""
                                }
                                alt={index.title}
                            />
                            {index.title}
                        </InternalLink>
                    </Item>
                );
            }
            // if (tags != null && tags.indexOf(indexOf.title) < 0) return null
            return (
                <Item key={`${index.title}-${i}`}>
                    <InternalLink
                        preventClick
                        {...index}
                        onClick={
                            menuIndexOpen != null
                                ? () => onSetThirdMenu(i)
                                : () => onShowMenuIndex(i)
                        }
                    >
                        <LazyLoadImage
                            effect="blur"
                            src={
                                index.thumbs && index.thumbs.length > 0
                                    ? index.thumbs[0]
                                    : ""
                            }
                            alt={index.title}
                        />
                        {index.title}
                    </InternalLink>
                </Item>
            );
        });

        return header;
    };

    React.useEffect(() => {
        const promises = [];
        promises.push(Blueberry.menu("menu-principal", true));
        promises.push(Blueberry.menu("menu-principal-login", true));
        setIsLoadingMenu(true);
        Promise.all(promises)
            .then((data) => {
                setIsLoadingMenu(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoadingMenu(false);
            });
    }, []);

    if (isLoadingMenu && config.length <= 0) {
        return (
            <DemoWrapMobile>
                <MobileNav>
                    <Title as="h3" size="5">
                        <Preloader />
                    </Title>
                    <nav>
                        <ul>
                            <Item>
                                <a>
                                    <figure>
                                        <Preloader square />
                                    </figure>
                                    <Preloader />
                                </a>
                            </Item>
                            <Item>
                                <a>
                                    <figure>
                                        <Preloader square />
                                    </figure>
                                    <Preloader />
                                </a>
                            </Item>
                            <Item>
                                <a>
                                    <figure>
                                        <Preloader square />
                                    </figure>
                                    <Preloader />
                                </a>
                            </Item>
                            <Item>
                                <a>
                                    <figure>
                                        <Preloader square />
                                    </figure>
                                    <Preloader />
                                </a>
                            </Item>
                            <Item>
                                <a>
                                    <figure>
                                        <Preloader square />
                                    </figure>
                                    <Preloader />
                                </a>
                            </Item>
                            <Item>
                                <a>
                                    <figure>
                                        <Preloader square />
                                    </figure>
                                    <Preloader />
                                </a>
                            </Item>
                            <Item>
                                <a>
                                    <figure>
                                        <Preloader square />
                                    </figure>
                                    <Preloader />
                                </a>
                            </Item>
                        </ul>
                    </nav>
                </MobileNav>
            </DemoWrapMobile>
        );
    }

    return (
        <>
            {/* <Reset /> */}

            <DemoWrapMobile>
                {menuIndexOpen == null ? (
                    <MobileNav>
                        <Title as="h3" size="5">
                            {_t("shoByCategory")}
                        </Title>
                        <nav>
                            <ul>{buildHeaderMovil()}</ul>
                            {isLoggedIn ? (
                                <Item>
                                    <InternalLink to="/collection/sale">
                                        <PercentIcon />

                                        {_t("sale")}
                                    </InternalLink>
                                </Item>
                            ) : null}
                        </nav>
                    </MobileNav>
                ) : null}

                {menuIndexOpen != null ? (
                    <MobileNav className="">
                        <Helmet>
                            <body className="hide-search-bar-standalone" />
                        </Helmet>
                        <Header>
                            <Icon onClick={backButton}>
                                <BackIcon icon="chevron-left" white />
                            </Icon>
                            <LazyLoadImage
                                effect="blur"
                                src={
                                    config[menuIndexOpen].images &&
                                    config[menuIndexOpen].images.length > 0
                                        ? config[menuIndexOpen].images[0]
                                        : ""
                                }
                                alt={menuTitle}
                            />
                            <div>
                                <Title as="h3" size="4">
                                    {menuTitle}
                                </Title>
                                <InternalLink
                                    {...config[menuIndexOpen]}
                                    replace={true}
                                >
                                    <Button primary white small>
                                        Shop All
                                    </Button>
                                </InternalLink>
                            </div>
                        </Header>
                        <nav>
                            <ul>
                                {menuToMap.map((index, i) => {
                                    return (
                                        <Item
                                            hasChildren
                                            addPadding
                                            key={`${index.title}-footer-${i}`}
                                            isOpen={
                                                i === menuIndexThirdLevelOpen
                                            }
                                            onClick={() => onSetThirdMenu(i)}
                                        >
                                            {index.title}
                                            {index.children &&
                                            index.children.length > 0 ? (
                                                <nav>
                                                    <ul>
                                                        {index.children.map(
                                                            (ind) => (
                                                                <li
                                                                    key={`${ind.title}-link-${i}`}
                                                                >
                                                                    <InternalLink
                                                                        {...ind}
                                                                        replace={
                                                                            true
                                                                        }
                                                                    >
                                                                        {
                                                                            ind.title
                                                                        }
                                                                    </InternalLink>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </nav>
                                            ) : null}
                                        </Item>
                                    );
                                })}
                            </ul>
                        </nav>
                    </MobileNav>
                ) : null}

                {/* <MobileNav> */}
                {/* 	<Title as="h3" size="5">Featured</Title> */}
                {/* 	<Feaured> */}
                {/* 		<ul> */}
                {/* 			<li> */}
                {/* 				<a href=""> */}
                {/* 					<img src="https://www.noritex.com/WK2KDaYz4dnbaBEM2hogar-navideno.jpg?d=300x300" alt=""/> */}
                {/* 					<h3>Hogar Navideño</h3> */}
                {/* 				</a> */}
                {/* 			</li> */}
                {/* 			<li> */}
                {/* 				<a href=""> */}
                {/* 					<img src="https://www.noritex.com/zbLoBNQRtuTPpQR6Potras-categorias.jpg?d=300x300" alt=""/> */}
                {/* 					<h3>Muñecos animados</h3> */}
                {/* 				</a> */}
                {/* 			</li> */}
                {/* 			<li> */}
                {/* 				<a href=""> */}
                {/* 					<img src="https://www.noritex.com/9RpYyPa2qhX9mDuEGcategorias.jpg?d=300x300" alt=""/> */}
                {/* 					<h3>Adornos para árboles</h3> */}
                {/* 				</a> */}
                {/* 			</li> */}
                {/* 			<li> */}
                {/* 				<a href=""> */}
                {/* 					<img src="https://www.noritex.com/WK2KDaYz4dnbaBEM2hogar-navideno.jpg?d=300x300" alt=""/> */}
                {/* 					<h3>Hogar Navideño</h3> */}
                {/* 				</a> */}
                {/* 			</li> */}
                {/* 		</ul> */}
                {/* 	</Feaured> */}
                {/* </MobileNav> */}
            </DemoWrapMobile>
        </>
    );
}

export default StandAloneMenu;
