import styled from "styled-components";
import { Button } from "../../sdk/Button";

export const Container = styled.div`
  position: relative;
  margin: auto;
  max-width: ${(props) => props.maxWidth ?? "1300px"};
  .logo {
    color: ${(props) => props.theme.primary};
	fill: ${(props) => props.theme.primary};
	width: 170px;
  }
  .logo.bigger {
	width: 260px;
  }
`;

export const Layout = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;1000&display=swap');
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #2A2B2B;

  @media (max-width: 980px) {
    .hide-on-tablet {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .hide-on-mobile {
      display: none;
    }
  }
`;

export const Grid = styled.div`
  display: grid;

  @media (min-width: 1024px) {
    grid-template-columns: ${(props) => props.desktop ? `repeat(${props.desktop}, 1fr)` : '1fr 1fr 1fr'};
    gap: ${(props) => props.desktopGap ?? '50px'};
  }
  @media (max-width: 1023px) {
    grid-template-columns: ${(props) => props.tablet ? `repeat(${props.tablet}, 1fr)` : '1fr 1fr'};
    gap: ${(props) => props.tabletGap ?? '30px'};
  }
  @media (max-width: 768px) {
    grid-template-columns: ${(props) => props.mobile ? `repeat(${props.mobile}, 1fr)` : '1fr'};
    gap: ${(props) => props.mobileGap ?? '20px'};
  }
`;

export const Cover = styled.div`
  position: relative;
  .bg {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    .image {
      position: relative;
      img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    ${Container} {
      width: 100%;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100vh;
      padding: 100px 20px;

      @media (max-width: 768px) {
        padding: 50px 20px;
      }
    }

    .headline {
      font-size: 20px;
      font-weight: normal;
      line-height: 1;
      display: inline-block;
      padding-right: 50px;
      position: relative;
      .line {
        position: absolute;
        top: 10px;
        left: 100%;
      }
    }
    .year {
      font-size: 200px;
      line-height: 1;
      margin-top: 10px;
    }
    .arrow-down {
      display: inline-block;
      cursor: pointer;
    }
  }

  @media (max-width: 980px) {
    .inner {
      .content {
        padding: 20px;
        .logo {
          svg {
            width: 150px;
            height: auto;
          }
        }
      }
      .headline {
        font-size: 18px;
      }
      .year {
        font-size: 100px;
      }
    }
  }

  @media (max-width: 768px) {
    .bg {
      grid-template-columns: 1fr;
      div:nth-child(1) {
        display: none;
      }

      .image {
        img {
          opacity: 0.5;
        }
      }
    }
  }
`;



export const Intro = styled.div`
  .inner {
    margin: auto;
    max-width: 1050px;
  }
  .line {
    position: absolute;
    left: 100%;
    top: 100%;
  }
`;

export const Spacer = styled.div`
  height: ${(props) => props.desktop}px;

  @media (max-width: 1023px) {
    height: ${(props) => props.tablet}px;
  }

  @media (max-width: 768px) {
    height: ${(props) => props.mobile}px;
  }
`;

export const Section = styled.div`
  padding: 100px 20px;
  position: relative;

  @media (max-width: 980px) {
    padding: 50px 10px;
  }
  @media (max-width: 768px) {
    padding: 30px 10px;
  }
`;

export const SectionExtended = styled(Section)`
  background: radial-gradient(320.99% 99.26% at 45.5% 7%, rgba(33, 128, 208, 0.12) 63.01%, rgba(0, 149, 1, 0.12) 100%);
  position: relative;
  .floating-image {
    width: 400px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .line {
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 100px;
  }
  
  @media (min-width: 981px) {
    padding-bottom: 200px;
  }
`;

export const Heading = styled.div`
  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    line-height: 1.5;
    font-size: ${(props) => props.size === 'big' ? '38px' : '32px'};
    text-align: ${(props) => props.align ?? 'left'};
  }
  
  @media (max-width: 768px) {
    h1, h2, h3, h4, h5, h6 {
      font-size: ${(props) => props.size === 'big' ? '32px' : '28px'};
    }
  }

  @media (max-width: 980px) {
    h1, h2, h3, h4, h5, h6 {
      /* text-align: center; */
      font-size: ${(props) => props.size === 'big' ? '28px' : '24px'};
    }
  }
`;

export const Paragraph = styled.div`
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
  max-width: 650px;
  margin: auto;
  p:not(:last-child) {
    margin-bottom: 30px;
  }
  text-align: ${(props) => props.align ?? 'left'};

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 26px;
  }
`;

export const Btn = styled(Button)`
  border-radius: 30px;
  &.borderless {
    border-color: transparent;
  }
  &.raised {
    box-shadow: 0px 4px 31px 15px rgba(0, 0, 0, 0.02);
  }
  &.large {
    height: 60px;
    font-size: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const InfoBlocks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    
    div {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 30px;
    > div {
      grid-template-columns: 1fr;
      gap: 20px;

      ${Heading} {
        h2 {
          text-align: left !important;
        }
      }
    }
  }
`;

export const Img = styled.figure`
  position: relative;
  background-color: ${(props) => props.theme.lightGray};
  box-shadow: 30px 30px 32px -23px rgba(0, 0, 0, 0.13);
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  &:before {
    content: "";
    display: block;
    padding-top: ${(props) => props.aspectRatio === 'square' ? '100%' : '75%'};
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
`;

export const CTA = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .image {
    position: relative;
    img {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    
    .inner {
      align-items: center;
      gap: 30px;
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Footer = styled.footer`
  background-color: ${(props) => props.theme.grayLight};
  padding-top: 100px;
  text-align: center;
  .logo {
    color: ${(props) => props.theme.primary};
  }
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    p {
      color: ${(props) => props.theme.primary};
    }
  }
  .social {
    display: flex;
    gap: 20px;
  }
  .copy {
    font-size: 14px;
    border-top: solid 1px #363636;
    padding: 10px 20px;
  }

  @media (max-width: 768px) {
    padding-top: 50px;
  }
`;