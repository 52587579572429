import React from "react";
import Blueberry from "../../../blueberry/index";
import { Helmet } from "react-helmet";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import withTracker from "../../../blueberry/connectPromise";
import { SectionExtended, ModalExtended } from "./style";
import { Button } from "../../sdk/Button";
import { Container, Section, BlankState } from "../../sdk/Layout";
import Icons2 from "../../components/Icons2";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import BubbleSearch from "../../components/BubbleSearch";
import Mosonry from "../../components/Mosonry";
import renderIf from "../../utils/renderIf";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

class Page extends ProductsSectionTemplate {
    state = {
        currentImage: null,
        children: null,
        searchTerms: [],
        isModalVisible: false,
        didSearch: false,
    };

    hanldeSetTerms = (terms) => {
        this.setState({ searchTerms: terms, isLoading: true });
        Blueberry.collectionChildren({ productSlugs: terms, limit: 50 })
            .then((resp) => {
                console.log(resp);
                this.setState({
                    children: resp,
                    didSearch: true,
                    isLoading: false,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    toggleModal = () => {
        this.setState((prev) => ({ isModalVisible: !prev.isModalVisible }));
    };

    handleOnClick = (currentImage) => {
        this.setState({ currentImage, isModalVisible: true });
    };

    renderStacks = () => {
        const { isLoading, children } = this.state;
        // if (isLoading || isLoadingMenu) return <Loader />;
        if (children == null) return null;
        return (
            <Mosonry
                children={children}
                loading={isLoading}
                onClick={this.handleOnClick}
                preventClick={true}
            />
        );
    };

    renderBody() {
        const { lang } = this.props.match.params;
        const { currentImage, isModalVisible, didSearch, isLoading, children } =
            this.state;
        const langJson = getLang(lang);
        return (
            <>
                <Helmet htmlAttributes={{ lang: lang }}>
                    <title>{langJson.title}</title>
                </Helmet>

                <SectionExtended>
                    <Section>
                        <Container>
                            <BubbleSearch
                                onChange={this.hanldeSetTerms}
                                placeholder={langJson.placeholder}
                                initialFocus={true}
                            />
                        </Container>
                    </Section>
                    <Section>
                        <Container>
                            {renderIf(
                                didSearch &&
                                    !isLoading &&
                                    children &&
                                    children.length <= 0
                            )(
                                <BlankState>
                                    <div>
                                        <span><Icons2 icon="blue-emptybox"></Icons2></span>
                                        <h3>{this._t("searchNotFound")}...</h3>
                                        <p>{this._t("noProductsFound")} </p>
                                    </div>
                                </BlankState>
                            )}
                            {renderIf(!didSearch && !isLoading)(
                                <BlankState>
                                    <div>
                                        <span><Icons2 icon="blue-search"></Icons2></span>
                                        <h3>{langJson.placeholder}</h3>
                                        {/* <p>{this._t("noProductsFound")} </p> */}
                                    </div>
                                </BlankState>
                            )}
                            {this.renderStacks()}
                        </Container>
                    </Section>
                </SectionExtended>
                <ModalExtended className={isModalVisible && "is-active"}>
                    <span onClick={this.toggleModal}></span>
                    <button onClick={this.toggleModal}></button>
                    <div>
                        <figure>
                            <img src={currentImage} alt="" />
                        </figure>
                    </div>
                </ModalExtended>
            </>
        );
    }
}

export default withTracker()(Page);

// export default withSSR(Page);
