import React from "react";
import Helmet from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Heading, Container, Section } from "../sdk/Layout";
import MainTemplate from "../templates/MainTemplate";
import withTracker from "../../blueberry/connectPromise";
import Blueberry from "../../blueberry/index";
import InternalLink from "../components/InternalLink";
import {
    Post,
    Text,
    PostList,
    Image,
    Title,
    Author,
    SectionExtended,
} from "./style";
import { getDate } from "../utils";

class Blog extends MainTemplate {
    state = {
        limit: 100,
        page: 1,
    };

    componentDidMount() {
        if (typeof window !== "undefined" && analytics)
            analytics.track("Blog Visited");
    }

    static async getInitialData(props) {
        // let { lang, slug } = props.match.params;

        return new Promise((resolve, reject) => {
            Blueberry.postList({ limit: 100, page: 1 })
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    console.log(err);
                    resolve({
                        data: {
                            title: "Protected page",
                            content: "",
                            protected: true,
                            status: "not logged in",
                            seo: { title: "", description: "" },
                        },
                    });
                });
        });
    }

    renderIf(statement, node) {
        return statement ? node : <div className="placeholder-img" />;
    }

    renderPost = (posts) => {
        if (posts == null || posts.posts == null) return null;
        return posts.posts.map((index) => {
            const { title, content, images, imageSetAutomatically, createdAt } =
                index;

            const date = new Date(createdAt);
            return (
                <Post key={index._id}>
                    <InternalLink
                        slug={index.slug}
                        type="blog"
                        className="post-box"
                    >
                        <Image>
                            <LazyLoadImage
                                src={
                                    images && images.length > 0
                                        ? images[0]
                                        : "https://www.noritex.com/F69u3uzZJZayfjMRXbg-no-image.jpeg"
                                }
                                alt={`${title} - Blog Noritex`}
                            />
                        </Image>
                        <Text>
                            <div>
                                <Title>{title}</Title>
                            </div>
                            <Author>
                                <div>
                                    <p>Noritex S.A</p>
                                    <span>{getDate(date)}</span>
                                </div>
                            </Author>
                        </Text>
                    </InternalLink>
                </Post>
            );
        });
    };

    renderBody() {
        return (
            <SectionExtended>
                <Helmet>
                    <title>Blog - Noritex</title>
                </Helmet>
                <Container>
                    <Heading isCentered>
                        <h1>Blog - Noritex</h1>
                    </Heading>
                    <PostList>
                        {this.renderPost(this.props.store.posts)}
                    </PostList>
                </Container>
            </SectionExtended>
        );
    }
}

// export default Blog

export default withTracker()(Blog);
