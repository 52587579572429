import React, { useState, useRef } from "react";
import { BoxExtended, Search, NewTerm, Term } from "./style";
import { Button } from "../../sdk/Button";
import Icons from "../Icons2";

function BubbleSearch({ onChange, placeholder, initialFocus }) {
    const [searchTerm, setSearchTerm] = useState("");
    const inputRef = useRef("");
    const [bubbleTerms, setBubbleTerms] = useState([]);

    const inputSize = () => {
        return searchTerm != "" ? searchTerm.length : "1";
    };

    const handleKeyUp = (event) => {
        if (event.keyCode === 13 && searchTerm.trim() != "") {
            setBubbleTerms((prev) => {
                const newBubbles = [
                    ...prev,
                    searchTerm.trim().replace("/", "-sz"),
                ];
                if (onChange) onChange(newBubbles);
                return newBubbles;
            });
            setSearchTerm("");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const handleRemoveItem = (pos) => {
        setBubbleTerms((prev) => {
            let newItems = [...prev];
            return newItems.filter((index, i) => pos !== i);
        });
    };

    React.useEffect(() => {
        if (initialFocus) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <BoxExtended>
            <form onSubmit={handleSubmit}>
                <Search htmlFor="input">
                    <input type="hidden" value={searchTerm} />
                    {bubbleTerms.map((item, i) => {
                        return (
                            <Term key={`bubble-${item}${i}`}>
                                {item}
                                <a onClick={() => handleRemoveItem(i)}>
                                    <Icons icon="close"></Icons>
                                </a>
                            </Term>
                        );
                    })}
                    <NewTerm>
                        <input
                            id="input"
                            ref={inputRef}
                            type="text"
                            value={searchTerm}
                            placeholder={placeholder}
                            onKeyUp={handleKeyUp}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </NewTerm>
                </Search>
                <Button small>
                    <Icons icon="search"></Icons>
                </Button>
            </form>
        </BoxExtended>
    );
}

export default BubbleSearch;
