import styled from "styled-components";
import { Title } from "../../../sdk/Layout";
import { Preloader } from "../../../sdk/Elements";

export const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${props => props.theme.borderColor};

    padding: 0px 10px;
    --safe-area-inset-top: env(safe-area-inset-top);
    padding-top: var(--safe-area-inset-top) !important;
    height: calc(55px + var(--safe-area-inset-top));
`;

export const CartIcon = styled.div`
    cursor: pointer;
    svg {
        width: 20px;
    }
`;

export const Cart = styled.div`
    padding: 20px;

    --safe-area-inset-top: env(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 16px) !important;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 10;
    background-color: #f8f8f8;
    border-left: solid 1px #e3e3e3;
    width: 96%;
    max-width: 340px;
    box-shadow: -2px 0 10px -7px rgba(0, 0, 0, 0.3);
    display: ${(props) => !props.isVisible && "none"};
`;

export const Header = styled.div`
    padding-bottom: 10px;
    > div {
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    ${Title} {
        margin-bottom: 0;
        color: #122b58;
        margin-left: 30px;
    }
    ul {
        text-align: right;
        li {
            font-size: 14px;
            line-height: 20px;
        }
    }
`;

export const Links = styled.span`
    display: block;
    padding-top: 20px;
    font-size: 0;
    a {
        display: inline-flex;
        vertical-align: middle;
        align-items: center;
        font-size: 13px;
        cursor: pointer;
        color: #343535;
        text-decoration: none;
        &:not(:last-child) {
            margin-right: 15px;
        }
        &:hover {
            text-decoration: underline;
        }
        svg {
            width: 13px;
            height: 13px;
            margin-right: 5px;
        }
    }
`;

export const Closer = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    display: block;
    svg {
        width: 13px;
    }
`;

export const Items = styled.div`
    margin-top: 10px;
    height: calc(100% - 86.5px);
    padding-right: 10px;
    margin-right: -10px;
    padding-bottom: 100px;
    overflow-y: auto;
    li {
        background-color: #fff;
        padding: 15px;
        border-radius: 5px;
        cursor: pointer;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &:hover {
            box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.1);
            svg {
                display: block;
            }
        }
        > div {
            display: flex;
            position: relative;
            padding-right: 60px;
        }
        img {
            width: 60px;
            height: 60px;
            object-fit: contain;
            object-position: center;
            margin-right: 5px;
        }
        h5 {
            font-size: 12px;
            line-height: 14px;
            display: block;
            margin-bottom: 10px;
            font-weight: normal;
        }
        span {
            font-size: 10px;
            font-weight: bold;
            color: #aaaaaa;
        }
        p {
            font-weight: bold;
            font-size: 12px;
            position: absolute;
            top: 0px;
            right: 0px;
        }
        a {
            text-decoration: underline;
            color: #122b58;
            font-size: 12px;
            display: none;
            &:hover {
                text-decoration: none;
            }
        }
        svg {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 20px;
            cursor: pointer;
            color: #a3a5a5;
            &:hover {
                color: #bf6a61;
            }
            @media (min-width: 1024px) {
                display: none;
            }
        }
    }
    & ${Preloader} {
        width: 100%;
    }
`;
