import styled from "styled-components";
import { Section, Box } from "../../sdk/Layout";

import { Button } from "../../sdk/Button";
import { Tag, Modal } from "../../sdk/Elements";

export const SectionExtended = styled(Section)`
    background-color: ${(props) => props.theme.grayLight};
`;

export const ModalExtended = styled(Modal)`
    z-index: 99999999;
    > div {
        max-width: calc(100% - 120px);
        display: flex;
    }
    figure {
        text-align: center;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
`;
