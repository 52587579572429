import React from "react";
import { Container, Title } from "../../../sdk/Layout";

import {
	WrapperFeatures,
	TextFeatures,
	Feature,
	ColFeatures,
	VideoFeatures,
} from "../style";
import { LazyLoadImage } from "react-lazy-load-image-component";

// 3. This function creates an <iframe> (and YouTube player)
//    after the API code downloads.
var player;

function onPlayerReady(event) {
	// event.target.playVideo();
}

function onYouTubeIframeAPIReady() {
	player = new YT.Player("player", {
		height: "390",
		width: "640",
		videoId: "EMTfTFkWHO8",
		events: {
			onReady: onPlayerReady,
		},
	});
}
if (typeof window !== "undefined") {
	window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
}
function injectTag() {
	var tag = document.createElement("script");

	tag.src = "https://www.youtube.com/iframe_api";
	var firstScriptTag = document.getElementsByTagName("script")[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function Features({ langJson }) {
	const [isModalVisible, setIsModalVisitble] = React.useState(false);
	const toggleModal = () => {
		setIsModalVisitble((prevState) => {
			if (prevState) player.stopVideo();
			if (!prevState && player == null) {
				injectTag();
			}
			return !prevState;
		});
	};

	React.useEffect(() => {
		var tag = document.createElement("script");

		tag.src = "https://www.youtube.com/iframe_api";
		var firstScriptTag = document.getElementsByTagName("script")[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	}, []);

	return (
		<>
			{langJson.features.map((index, i) => (
				<WrapperFeatures key={`feature-${i}`}>
					<Container>
						<Feature>
							{i % 2 === 0 ? (
								<TextFeatures>
									<Title as="h2" size="1">
										{index.title}
									</Title>
									<p>{index.paragraph}</p>
									{/* <a href="">Conocer más</a> */}
								</TextFeatures>
							) : (
								<ColFeatures>
									<figure>
										{index.action === "modal" ? (
											<span onClick={toggleModal}>
												<svg
													width="10px"
													aria-hidden="true"
													focusable="false"
													data-prefix="fas"
													data-icon="play"
													role="img"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 448 512"
													className="svg-inline--fa fa-play fa-w-14">
													<path
														fill="currentColor"
														d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
													/>
												</svg>
											</span>
										) : null}
										<LazyLoadImage src={index.img} alt="" />
									</figure>
								</ColFeatures>
							)}
							{i % 2 === 0 ? (
								<ColFeatures>
									<figure>
										<img src={index.img} alt="" />
									</figure>
								</ColFeatures>
							) : (
								<TextFeatures>
									<Title as="h2" size="1">
										{index.title}
									</Title>
									<p>{index.paragraph}</p>
									{/* <a href="">Conocer más</a> */}
								</TextFeatures>
							)}
						</Feature>
					</Container>
				</WrapperFeatures>
			))}

			<VideoFeatures className={isModalVisible ? "is-active" : ""}>
				<button onClick={toggleModal}></button>
				<span onClick={toggleModal}></span>
				<div>
					{/* <iframe
                        src="https://www.youtube.com/embed/sHAMYLAdmyI"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe> */}
					<div id="player"></div>
				</div>
			</VideoFeatures>
		</>
	);
}

export default Features;
