import React from "react";
import { Helmet } from "react-helmet";
import MainTemplate from "../templates/MainTemplate";
import withTracker from "../../blueberry/connectPromise";

import { Heading } from "../sdk/Layout";
import { Container, Section } from "../sdk/Layout";
import Blueberry from "../../blueberry";
import BrandList from "../components/BrandList";
import { NotFound } from "../NoMatch";
import useQueryString from "../hooks/useQueryString";
import { getParameterByNameNoReplace } from "../utils";
import SEOFieldsHelmet from "../utils/SEOFieldsHelmet";

function getData({ page, slug }) {
    return new Promise((resolve) => {
        Blueberry.brandCollections({
            slug: slug,
            page: page ? parseInt(page) : 1,
            limit: 36,
        })
            .then((resp) => {
                resolve(resp);
            })
            .catch((err) => {
                resolve({});
            });
    });
}

function BrandCollectionList({ data, _t, lang, slug, menusData, goToSearch }) {
    const [page, setPage] = useQueryString("page", 1);
    const [limit, setLimit] = useQueryString("limit", 36);

    React.useEffect(() => {
        getData({ page, slug });
    }, [page]);

    if (data == null) return null;

    const dataFinal = data[parseInt(page) - 1];

    if (dataFinal && dataFinal.status === "404") {
        return (
            <>
                <NotFound menusData={menusData} goToSearch={goToSearch} />
            </>
        );
    }
    return (
        <Section>
            <SEOFieldsHelmet
                seo={data.seo}
                defaultDescription=""
                defaultTitle={_t("ourBrands")}
                lang={lang}
            />
            <Heading isCentered>
                <h1>{_t("ourBrands")}</h1>
            </Heading>

            <BrandList
                data={dataFinal}
                currentPage={parseInt(page)}
                limit={parseInt(limit)}
                setPage={setPage}
				lang={lang}
            />
        </Section>
    );
}

class BrandCollection extends MainTemplate {
    static async getInitialData(props) {
        console.log(props);
        const page = getParameterByNameNoReplace("page", props.match.path);
        return getData({ page, slug: props.match.params.slug });
    }
    renderBody() {
        const { brands, menusData } = this.props.store;
        const { slug, lang } = this.props.match.params;

        return (
            <>
                <BrandCollectionList
                    data={slug == null ? brands.null : brands[slug]}
                    slug={slug}
                    _t={this._t}
                    lang={lang}
                    goToSearch={this.goToSearch}
                    menusData={menusData}
                />
            </>
        );
    }
}

export default withTracker()(BrandCollection);
