import React, { useState } from "react";
import styled from "styled-components";
import { Title } from "../../../sdk/Layout";
import { Hero } from "../style";

function HeroSection({ langJson }) {
    return (
        <>
            <Hero>
                <Title size="1">{langJson.superTitle}</Title>
            </Hero>
        </>
    );
}

export default HeroSection;
