import React from "react";
import { Container, Column, Title } from "../../../sdk/Layout";

import {
	WrapperProducts,
	TextProducts,
	ImageProducts,
	ColsProducts,
} from "../style";

function NuestrosProductos({ langJson }) {
	return (
		<>
			<WrapperProducts>
				<Container>
					<ColsProducts>
						<TextProducts isNarrow>
							<Title as="h2" size="1">
								{langJson.productsTitle}
							</Title>
							<Title as="h3" size="4">
								{langJson.productsHelp}
							</Title>
							<p>{langJson.productsHelp2}</p>
							<p>{langJson.productsHelp3}</p>
						</TextProducts>
						<Column>
							<ImageProducts>
								<img
									src="https://www.noritex.com/w5ztYYXAAeJh3bRjbtribal-web.jpg"
									alt=""
								/>
							</ImageProducts>
						</Column>
					</ColsProducts>
				</Container>
			</WrapperProducts>
		</>
	);
}

export default NuestrosProductos;
