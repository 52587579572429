import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Img } from '../style';

export default function Image({ src, alt, aspectRatio, className}) {
    return (
      <Img aspectRatio={aspectRatio} className={className}>
        <LazyLoadImage 
            src={src}
            alt={alt}
        />
      </Img>
    )
}